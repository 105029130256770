import React from "react";
import { graphql } from "gatsby";
import Container from "../components/container";
import GraphQLErrorList from "../components/graphql-error-list";
import MainTopic from "../components/main-topic";
import SEO from "../components/seo";
import Layout from "../containers/layout";

export const query = graphql`
  query MainTopicTemplateQuery($id: String!) {
    initiatives: allSanityInitiative(
      filter: { mainTopics: { elemMatch: { id: { eq: $id } } } }
      sort: { fields: displayPosition }
    ) {
      edges {
        node {
          name
          id
          website
          tagline
          displayPosition
          mainTopics {
            id
            title
            slug {
              current
            }
          }
        }
      }
    }
    mainTopic: sanityMainTopic(id: { eq: $id }) {
      id
      title
      description
      slug {
        current
      }
    }
  }
`;

const MainTopicTemplate = (props) => {
  const { data, errors, pageContext } = props;
  const mainTopic = data && data.mainTopic;
  const initiatives = data && data.initiatives;
  const { index } = pageContext;
  return (
    <Layout>
      {errors && <SEO title="GraphQL Error" />}
      {mainTopic && <SEO title={mainTopic.title || "Untitled"} />}

      {errors && (
        <Container>
          <GraphQLErrorList errors={errors} />
        </Container>
      )}
      {mainTopic && initiatives && (
        <MainTopic mainTopic={mainTopic} initiatives={initiatives} index={index + 1} />
      )}
    </Layout>
  );
};

export default MainTopicTemplate;
