import React from "react";
import Container from "./container";
import Initiative from "./initiative";

import { cn, escapeHTMLBreakable } from "../lib/helpers";

import * as styles from "./main-topic.module.css";

function MainTopic(props) {
  const { mainTopic, index } = props;
  const initiatives = props.initiatives.edges.map(({ node }) => node) || [];
  const { title, description } = mainTopic;
  return (
    <article className={styles.root}>
      <Container>
        <div className={styles.mainContent}>
          <div className={styles.topSection}>
            <div className={cn(styles.hero, styles[`mainTopic${index}`])}>
              <p dangerouslySetInnerHTML={{ __html: escapeHTMLBreakable(title) }}></p>
            </div>
            <div className={styles.description}>{description}</div>
          </div>
          {initiatives && initiatives.length > 0 && (
            <div className={styles.initiatives}>
              <ul>
                {initiatives.map((initiative) => (
                  <Initiative key={initiative.id} {...initiative}></Initiative>
                ))}
              </ul>
            </div>
          )}
        </div>
      </Container>
    </article>
  );
}

export default MainTopic;
